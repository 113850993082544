import baseColors from './baseColors';

const colors = {
  // Base named colors
  ...baseColors,

  // semantic use-cases
  commercial: {
    base: 'var(--commercial-base)',
    bg: [ 'neutral', '-7', ],
  },
  contactUs: {
    uploadedFileBox: [ 'primary', 'base', ],
    needMoreHelpSelectTextColor: [ 'primary', 'base', ],
    fileUploaderBackgroundColor: 'var(--contactUs-fileUploader-bg)',
    fileUploaderBorderColor: 'var(--contactUs-fileUploader-border)',
    fileUploaderColor: 'var(--contactUs-fileUploader-color)',
    subjectButtonListColor: 'var(--contactUs-content-color)',
    subjectButtonColor: 'var(--contactUs-subjectButton-color)',
    subjectButtonActiveBorderColor: 'var(--contactUs-subjectButton-activeBorder)',
    subjectButtonBorderColor: 'var(--contactUs-subjectButton-border)',
    subjectButtonFillActiveColor: [ 'quaternary', '-1', ],
    subjectButtonFillColor: [ 'neutral', '-6', ],
    subjectButtonBg: 'var(--contactUs-subjectButton-bg)',
    questionColor: 'var(--contactUs-question-color)',
    questionActiveColor: 'var(--contactUs-question-activeColor)',
    questionBackgroundColor: 'var(--contactUs-question-bg)',
    questionsTitleColor: 'var(--contactUs-questionTitle-color)',
    answerHrefColor: [ 'primary', 'base', ],
    thankYouFormColor: [ 'primary', 'base', ],
    footerColor: 'var(--contactUs-footer-color)',
  },
  accent: {
    base: [ 'tertiary', 'base', ],
  },
  account: {
    title: [ 'neutral', '-2', ],
  },
  alerts: {
    bg: [ 'primary', '-6', ],
    openButtonText: [ 'tertiary', 'base', ],
    openButtonTextFollow: [ 'neutral', '-4', ],
    openButtonTextUnfollow: [ 'tertiary', 'base', ],
  },
  articleHeader: {
    titleText: 'inherit',
    kickerBlockBg: [ 'tertiary', ],
    kickerBlockText: [ 'neutral', '-10', ],
    kickerInlineBg: 'transparent',
    kickerInlineText: [ 'tertiary', ],
    metaTime: 'inherit',
    metaBorder: 'var(--articleHeader-metaBorder)',
    liveBlogAuthors: 'var(--articleHeader-liveBlogAuthors)',
    headlineCaptionText: 'inherit',
    headlineCaptionTextMobile: 'white',
    headlineCaptionBgMobile: [ 'neutral', 'base', ],
    highlightMobileCaptionBgc: [ 'neutral', '-1', ],
    reportingFrom: [ 'primary', ],
    aVariationDates: 'var(--articleHeader-aVariationDates)',
    logoColor: [ 'primary', 'base', ],
  },
  articleLink: {
    currentArticleBg: 'var(--articleLink-currentArticleBg)',
    currentArticleText: 'var(--articleLink-currentArticleText)',
    color: 'var(--articleLink-color)',
    colorBlockLink: [ 'primary', 'base', ],
    borderBlockLink: 'inherit',
    hoverColor: [ 'primary', '+1', ],
    bgImage: [ 'quaternary', 'base', ],
    bgImageGradient: [ 'quaternary', '-2', ],
    title: [ 'neutral', '-1', ],
    titleLinksBlock: 'var(--articleLink-titleLinksBlock)',
    hoverBlockLink: 'inherit',
  },
  bg: {
    base: 'var(--main-bg-color)',
    '+1': [ 'primary', '-5', ],
  },
  bodyText: {
    base: 'var(--main-text-color)',
    inverse: [ 'neutral', '-6', ],
  },
  boxy: {
    innerTextBg: [ 'quaternary', 'base', ],
    innerText: [ 'neutral', '-1', ],

    innerTextBgLabel: [ 'neutral', '-1', ],
    innerTextLabel: [ 'neutral', '-10', ],
  },
  breadCrumbs: {
    first: [ 'primary', 'base', ],
    firstHover: [ 'primary', '+1', ],
    rest: [ 'neutral', '-2', ],
    restHover: [ 'neutral', '-1', ],
    firstWebView: '#86d8f3',
  },
  button: {
    // Primary
    primaryBg: 'white',
    primaryBorder: [ 'primary', 'base', ],
    primaryText: [ 'primary', 'base', ],
    primaryActiveBg: [ 'primary', '-6', ],
    primaryActiveBorder: [ 'button', 'primaryText', ],
    primaryActiveText: [ 'primary', '+1', ],
    primaryFocusBg: [ 'primary', 'base', ],
    primaryFocusBorder: [ 'button', 'primaryFocusBg', ],
    primaryFocusText: 'white',
    primaryHoverBg: [ 'primary', '-3', ],
    primaryHoverBorder: [ 'button', 'primaryHoverText', ],
    primaryHoverText: [ 'primary', '+1', ],

    // Primary Opaque
    primaryOpaqueBg: [ 'primary', 'base', ],
    primaryOpaqueBorder: 'transparent',
    primaryOpaqueText: 'white',
    primaryOpaqueActiveBg: [ 'primary', '+1', ],
    primaryOpaqueActiveBorder: 'transparent',
    primaryOpaqueActiveText: 'white',
    primaryOpaqueFocusBg: [ 'secondary', 'base', ],
    primaryOpaqueFocusBorder: [ 'neutral', '+2', ],
    primaryOpaqueFocusText: 'white',
    primaryOpaqueHoverBg: [ 'primary', '+1', ],
    primaryOpaqueHoverBorder: 'transparent',
    primaryOpaqueHoverText: 'white',

    // primaryDark
    primaryDarkBg: 'black',
    primaryDarkBorder: 'var(--button-primaryDarkText)',
    primaryDarkText: 'var(--button-primaryDarkText)',
    primaryDarkActiveBg: [ 'button', 'primaryText', ],
    primaryDarkActiveBorder: [ 'button', 'primaryActiveBg', ],
    primaryDarkActiveText: 'var(--button-primaryDarkActiveText)',
    primaryDarkFocusBg: [ 'button', 'primaryActiveBg', ],
    primaryDarkFocusBorder: [ 'button', 'primaryFocusBg', ],
    primaryDarkFocusText: [ 'button', 'primaryActiveText', ],
    primaryDarkHoverBg: 'var(--button-primaryDarkHoverBg)',
    primaryDarkHoverBorder: [ 'button', 'primaryHoverText', ],
    primaryDarkHoverText: [ 'primary', 'base', ],

    // Secondary
    secondaryBg: 'white',
    secondaryBorder: [ 'button', 'secondaryText', ],
    secondaryText: [ 'secondary', 'base', ],
    secondaryActiveBg: '#fff !important',
    secondaryActiveBorder: [ 'button', 'secondaryText', ],
    secondaryActiveText: [ 'secondary', 'base', ],
    secondaryFocusBg: [ 'secondary', 'base', ],
    secondaryFocusBorder: [ 'button', 'secondaryFocusBg', ],
    secondaryFocusText: 'white',
    secondaryHoverBg: [ 'primary', '-6', ],
    secondaryHoverBorder: [ 'button', 'secondaryHoverText', ],
    secondaryHoverText: [ 'secondary', '+1', ],

    // Secondary Opaque
    secondaryOpaqueBg: [ 'secondary', 'base', ],
    secondaryOpaqueBorder: 'transparent',
    secondaryOpaqueText: 'white',
    secondaryOpaqueActiveBg: [ 'secondary', '+1', ],
    secondaryOpaqueActiveBorder: 'transparent',
    secondaryOpaqueActiveText: 'white',
    secondaryOpaqueFocusBg: [ 'secondary', '+1', ],
    secondaryOpaqueFocusBorder: 'white',
    secondaryOpaqueFocusText: 'rgb(255,255,254)',
    secondaryOpaqueHoverBg: [ 'secondary', '+1', ],
    secondaryOpaqueHoverBorder: 'transparent',
    secondaryOpaqueHoverText: 'white',

    // Neutral
    neutralBg: 'transparent',
    neutralBorder: [ 'button', 'neutralText', ],
    neutralText: [ 'neutral', '-1', ],
    neutralActiveBg: 'transparent !important',
    neutralActiveBorder: [ 'button', 'neutralText', ],
    neutralActiveText: [ 'button', 'neutralText', ],
    neutralFocusBg: [ 'neutral', '-1', ],
    neutralFocusBorder: [ 'button', 'neutralFocusBg', ],
    neutralFocusText: 'hsl(0,0%,100%)',
    neutralHoverBg: 'rgba(0,0,0,.1)',
    neutralHoverBorder: [ 'button', 'neutralText', ],
    neutralHoverText: [ 'button', 'neutralText', ],
    // Neutral Opaque

    neutralOpaqueBg: [ 'neutral', '-1', ],
    neutralOpaqueBorder: 'transparent',
    neutralOpaqueText: [ 'neutral', '-6', ],
    neutralOpaqueActiveBg: [ 'neutral', '-4', ],
    neutralOpaqueActiveBorder: 'transparent',
    neutralOpaqueActiveText: [ 'neutral', 'base', ],
    neutralOpaqueFocusBg: [ 'neutral', '-2', ],
    neutralOpaqueFocusBorder: [ 'neutral', '+2', ],
    neutralOpaqueFocusText: 'white',
    neutralOpaqueHoverBg: [ 'neutral', '-2', ],
    neutralOpaqueHoverBorder: 'transparent',
    neutralOpaqueHoverText: 'white',

    // Negative
    negativeBg: 'white',
    negativeBorder: 'var(--button-negativeBorder)',
    negativeText: [ 'negative', 'base', ],
    negativeActiveBg: '#fff !important',
    negativeActiveBorder: [ 'button', 'negativeText', ],
    negativeActiveText: [ 'negative', 'base', ],
    negativeFocusBg: [ 'negative', 'base', ],
    negativeFocusBorder: 'transparent',
    negativeFocusText: '#fff',
    negativeHoverBg: [ 'tertiary', '-4', ],
    negativeHoverBorder: [ 'button', 'negativeHoverText', ],
    negativeHoverText: [ 'negative', '+1', ],

    // Negative Opaque
    negativeOpaqueBg: [ 'negative', 'base', ],
    negativeOpaqueBorder: 'transparent',
    negativeOpaqueText: 'white',
    negativeOpaqueActiveBg: [ 'negative', 'base', ],
    negativeOpaqueActiveBorder: 'transparent',
    negativeOpaqueActiveText: 'white',
    negativeOpaqueFocusBg: [ 'negative', '+1', ],
    negativeOpaqueFocusBorder: [ 'neutral', 'base', ],
    negativeOpaqueFocusText: 'rgba(255,255,254,0.99)',
    negativeOpaqueHoverBg: [ 'negative', '+1', ],
    negativeOpaqueHoverBorder: 'transparent',
    negativeOpaqueHoverText: 'white',

    // Positive
    positiveBg: 'white',
    positiveBorder: [ 'button', 'positiveText', ],
    positiveText: [ 'positive', 'base', ],
    positiveActiveBg: '#fff !important',
    positiveActiveBorder: [ 'button', 'positiveText', ],
    positiveActiveText: [ 'positive', 'base', ],
    positiveFocusBg: [ 'positive', '-2', ],
    positiveFocusBorder: 'transparent',
    positiveFocusText: [ 'neutral', 'base', ],
    positiveHoverBg: [ 'positive', '-3', ],
    positiveHoverBorder: [ 'button', 'positiveHoverText', ],
    positiveHoverText: [ 'positive', '+1', ],

    // Positive Opaque
    positiveOpaqueBg: [ 'positive', 'base', ],
    positiveOpaqueBorder: 'transparent',
    positiveOpaqueText: 'white',
    positiveOpaqueActiveBg: [ 'positive', 'base', ],
    positiveOpaqueActiveBorder: 'transparent',
    positiveOpaqueActiveText: 'white',
    positiveOpaqueFocusBg: [ 'positive', '-1', ],
    positiveOpaqueFocusBorder: 'transparent',
    positiveOpaqueFocusText: [ 'neutral', 'base', ],
    positiveOpaqueHoverBg: [ 'positive', '-2', ],
    positiveOpaqueHoverBorder: 'transparent',
    positiveOpaqueHoverText: [ 'neutral', 'base', ],

    // Inverse
    inverseBg: 'transparent',
    inverseBorder: 'white',
    inverseText: 'white',
    inverseActiveBg: 'transparent',
    inverseActiveBorder: 'white',
    inverseActiveText: 'white',
    inverseFocusBg: 'rgba(255,255,255,0.4)',
    inverseFocusBorder: [ 'neutral', '-1', ],
    inverseFocusText: [ 'neutral', '-1', ],
    inverseHoverBg: 'rgba(255,255,255,0.2)',
    inverseHoverBorder: 'white',
    inverseHoverText: 'white',

    // Inverse Opaque
    inverseOpaqueBg: 'white',
    inverseOpaqueBorder: 'white',
    inverseOpaqueText: [ 'primary', 'base', ],
    inverseOpaqueActiveBg: 'var(--button-inverseOpaqueActiveBg)',
    inverseOpaqueActiveBorder: 'white',
    inverseOpaqueActiveText: [ 'primary', 'base', ],
    inverseOpaqueFocusBg: 'var(--button-inverseOpaqueFocusBg)',
    inverseOpaqueFocusBorder: 'white',
    inverseOpaqueFocusText: [ 'primary', 'base', ],
    inverseOpaqueHoverBg: 'var(--button-inverseOpaqueHoverBg)',
    inverseOpaqueHoverBorder: 'white',
    inverseOpaqueHoverText: [ 'primary', 'base', ],

    // Facebook
    facebookBg: 'white',
    facebookBorder: [ 'button', 'facebookText', ],
    facebookText: [ 'facebook', 'base', ],
    facebookActiveBg: '#fff !important',
    facebookActiveBorder: [ 'button', 'facebookText', ],
    facebookActiveText: [ 'facebook', 'base', ],
    facebookFocusBg: [ 'facebook', 'base', ],
    facebookFocusBorder: 'transparent',
    facebookFocusText: 'rgba(255, 255, 255, 1)',
    facebookHoverBg: [ 'facebook', '-2', ],
    facebookHoverBorder: [ 'button', 'facebookHoverText', ],
    facebookHoverText: [ 'facebook', '+1', ],

    // Opaque Facebook button
    facebookOpaqueBg: [ 'facebook', 'base', ],
    facebookOpaqueBorder: 'transparent',
    facebookOpaqueText: 'white',
    facebookOpaqueActiveBg: [ 'facebook', '-1', ],
    facebookOpaqueActiveBorder: 'transparent',
    facebookOpaqueActiveText: 'white',
    facebookOpaqueFocusBg: [ 'facebook', '+1', ],
    facebookOpaqueFocusBorder: 'transparent',
    facebookOpaqueFocusText: 'white',
    facebookOpaqueHoverBg: [ 'facebook', '+1', ],
    facebookOpaqueHoverBorder: 'transparent',
    facebookOpaqueHoverText: 'white',

    // Opaque formatting button
    formattingOpaqueBg: [ 'primary', '-6', ],
    formattingOpaqueBorder: 'transparent',
    formattingOpaqueText: [ 'neutral', '-1', ],
    formattingOpaqueActiveBg: [ 'primary', 'base', ],
    formattingOpaqueActiveBorder: 'transparent',
    formattingOpaqueActiveText: 'white',
    formattingOpaqueFocusBg: [ 'primary', 'base', ],
    formattingOpaqueFocusBorder: 'transparent',
    formattingOpaqueFocusText: 'white',
    formattingOpaqueHoverBg: [ 'primary', '-5', ],
    formattingOpaqueHoverBorder: 'transparent',
    formattingOpaqueHoverText: [ 'neutral', '-1', ],

    // Twitter
    twitterBg: 'white',
    twitterBorder: [ 'button', 'twitterText', ],
    twitterText: [ 'twitter', 'base', ],
    twitterActiveBg: '#fff !important',
    twitterActiveBorder: [ 'button', 'twitterText', ],
    twitterActiveText: [ 'button', 'twitterText', ],
    twitterFocusBg: [ 'twitter', 'base', ],
    twitterFocusBorder: 'transparent',
    twitterFocusText: 'hsl(0, 0%, 100%)',
    twitterHoverBg: [ 'twitter', '-2', ],
    twitterHoverBorder: [ 'button', 'twitterHoverText', ],
    twitterHoverText: [ 'twitter', '+1', ],

    // Opaque Twitter button
    twitterOpaqueBg: [ 'twitter', 'base', ],
    twitterOpaqueBorder: 'transparent',
    twitterOpaqueText: 'white',
    twitterOpaqueActiveBg: [ 'twitter', '-1', ],
    twitterOpaqueActiveBorder: 'transparent',
    twitterOpaqueActiveText: 'white',
    twitterOpaqueFocusBg: [ 'twitter', '+1', ],
    twitterOpaqueFocusBorder: 'transparent',
    twitterOpaqueFocusText: 'white',
    twitterOpaqueHoverBg: [ 'twitter', '+1', ],
    twitterOpaqueHoverBorder: 'transparent',
    twitterOpaqueHoverText: 'white',

    // whatsapp
    whatsappBg: 'white',
    whatsappBorder: [ 'button', 'whatsappText', ],
    whatsappText: [ 'whatsapp', 'base', ],
    whatsappActiveBg: '#fff !important',
    whatsappActiveBorder: [ 'button', 'whatsappText', ],
    whatsappActiveText: [ 'button', 'whatsappText', ],
    whatsappFocusBg: [ 'whatsapp', 'base', ],
    whatsappFocusBorder: 'transparent',
    whatsappFocusText: 'hsla(0, 0%, 100%, 1)',
    whatsappHoverBg: [ 'whatsapp', '-2', ],
    whatsappHoverBorder: [ 'button', 'whatsappHoverText', ],
    whatsappHoverText: [ 'whatsapp', '+1', ],

    // Opaque whatsapp button
    whatsappOpaqueBg: [ 'whatsapp', 'base', ],
    whatsappOpaqueBorder: 'transparent',
    whatsappOpaqueText: [ 'bodyText', 'base', ],
    whatsappOpaqueActiveBg: [ 'whatsapp', '+2', ],
    whatsappOpaqueActiveBorder: 'transparent',
    whatsappOpaqueActiveText: [ 'bodyText', 'base', ],
    whatsappOpaqueFocusBg: [ 'whatsapp', '+1', ],
    whatsappOpaqueFocusBorder: 'transparent',
    whatsappOpaqueFocusText: [ 'bodyText', 'base', ],
    whatsappOpaqueHoverBg: [ 'whatsapp', '+1', ],
    whatsappOpaqueHoverBorder: 'transparent',
    whatsappOpaqueHoverText: [ 'bodyText', 'base', ],

    // Sales
    salesBg: 'white',
    salesBorder: [ 'button', 'salesText', ],
    salesText: [ 'sales', 'a11yOnLight', ],
    salesActiveBg: '#fff !important',
    salesActiveBorder: [ 'button', 'salesText', ],
    salesActiveText: [ 'button', 'salesText', ],
    salesFocusBg: [ 'sales', 'base', ],
    salesFocusBorder: 'transparent',
    salesFocusText: [ 'neutral', '-1', ],
    salesHoverBg: [ 'sales', '-2', ],
    salesHoverBorder: [ 'button', 'salesHoverText', ],
    salesHoverText: [ 'button', 'salesText', ],

    // Opaque Sales button
    salesOpaqueBg: [ 'sales', 'base', ],
    salesOpaqueBorder: 'transparent',
    salesOpaqueText: [ 'neutral', '-1', ],
    salesOpaqueActiveBg: [ 'sales', '+1', ],
    salesOpaqueActiveBorder: 'transparent',
    salesOpaqueActiveText: [ 'button', 'salesOpaqueText', ],
    salesOpaqueFocusBg: [ 'sales', '+2', ],
    salesOpaqueFocusBorder: [ 'neutral', '+2', ],
    salesOpaqueFocusText: [ 'button', 'salesOpaqueText', ],
    salesOpaqueHoverBg: [ 'sales', '+2', ],
    salesOpaqueHoverBorder: 'transparent',
    salesOpaqueHoverText: [ 'button', 'salesOpaqueText', ],

    sharebarText: [ 'neutral', '-3', ],
    sharebarBg: 'white',
    sharebarActiveBg: [ 'neutral', '-3', ],
    sharebarActiveBorder: 'transparent',
    sharebarActiveText: 'white',
    sharebarFocusBg: [ 'neutral', '-1', ],
    sharebarFocusBorder: 'transparent',
    sharebarFocusText: [ 'button', 'salesOpaqueText', ],
    sharebarHoverBg: [ 'neutral', '-3', ],
    sharebarHoverBorder: 'transparent',
    sharebarHoverText: 'white',

    // Commercial button - tm theme, this is not final htz color.
    // We added this here to have fb when
    // commercial button variant is used in htz.
    commercialBg: '#ebebeb',
    commercialBorder: [ 'commercial', 'base', ],
    commercialText: [ 'commercial', 'base', ],
    commercialActiveBg: '#fff !important',
    commercialActiveBorder: [ 'commercial', 'base', ],
    commercialActiveText: [ 'commercial', 'base', ],
    commercialFocusBg: 'white',
    commercialFocusBorder: 'transparent',
    commercialFocusText: [ 'commercial', 'base', ],
    commercialHoverBg: [ 'neutral', '-7', ],
    commercialHoverBorder: [ 'commercial', 'base', ],
    commercialHoverText: [ 'commercial', 'base', ],

    // algolia search button
    algoliaText: [ 'primary', 'base', ],

    // pagination
    paginationBg: 'var(--button-paginationBg)',
    paginationBorder: 'var(--button-paginationBorder)',
    paginationText: 'var(--button-paginationText)',
    paginationActiveBg: 'var(--button-paginationActiveBg)',
    paginationActiveBorder: 'var(--button-paginationActiveBorder)',
    paginationActiveText: 'var(--button-paginationActiveText)',
    paginationFocusBg: 'var(--button-paginationFocusBg)',
    paginationFocusBorder: 'var(--button-paginationFocusBorder)',
    paginationFocusText: 'var(--button-paginationFocusText)',
    paginationHoverBg: 'var(--button-paginationHoverBg)',
    paginationHoverBorder: 'var(--button-paginationHoverBorder)',
    paginationHoverText: 'var(--button-paginationHoverText)',


    // Default button theme
    defaultButtonThemeBg: 'white',
    defaultButtonThemeText: [ 'primary', 'base', ],
    defaultButtonThemeBorder: [ 'button', 'primaryText', ],
    defaultButtonThemeActiveBg: [ 'primary', '-6', ],
    defaultButtonThemeActiveBorder: [ 'button', 'primaryText', ],
    defaultButtonThemeActiveText: [ 'primary', '+1', ],
    defaultButtonThemeFocusBg: [ 'primary', 'base', ],
    defaultButtonThemeFocusBorder: [ 'button', 'primaryFocusBg', ],
    defaultButtonThemeFocusText: 'white',
    defaultButtonThemeHoverBg: [ 'primary', '-3', ],
    defaultButtonThemeHoverBorder: [ 'button', 'primaryHoverText', ],
    defaultButtonThemeHoverText: [ 'primary', '+1', ],
  },
  breakingNews: {
    layout: [ 'primary', '-6', ],
    dayAndDate: [ 'primary', ],
  },
  breakingNewsBox: {
    stripController: [ 'primary', ],
  },
  biography: {
    link: [ 'neutral', '-10', ],
    linkHover: [ 'neutral', '+2', ],
    linkFocus: [ 'neutral', '+2', ],
    linkVisited: [ 'neutral', '+2', ],
    linkActive: [ 'neutral', '+2', ],
  },
  checkBox: {
    // Primary
    primaryBg: 'white',
    primaryBgChecked: [ 'primary', 'base', ],
    primaryBorder: [ 'primary', 'base', ],
    primaryBorderDisabled: [ 'neutral', '-5', ],
    primaryCheck: 'white',
    primaryRipple: [ 'primary', 'base', ],

    // Secondary
    secondaryBg: 'white',
    secondaryBgChecked: [ 'secondary', 'base', ],
    secondaryBorder: [ 'secondary', 'base', ],
    secondaryBorderDisabled: [ 'neutral', '-5', ],
    secondaryCheck: 'white',
    secondaryRipple: [ 'secondary', 'base', ],

    // Neutral
    neutralBg: 'white',
    neutralBgChecked: [ 'neutral', '-2', ],
    neutralBorder: [ 'neutral', '-2', ],
    neutralBorderDisabled: [ 'neutral', '-5', ],
    neutralCheck: 'white',
    neutralRipple: [ 'neutral', '-2', ],

    // Tertiary
    tertiaryBg: 'white',
    tertiaryBgChecked: [ 'tertiary', 'base', ],
    tertiaryBorder: [ 'tertiary', 'base', ],
    tertiaryBorderDisabled: [ 'neutral', '-5', ],
    tertiaryCheck: 'white',
    tertiaryRipple: [ 'tertiary', 'base', ],
  },

  credit: {
    creditArticleText: 'var(--credit-creditArticleText)',
  },

  footer: {
    bg: [ 'secondary', 'base', ],
    border: 'white',
    text: 'white',
  },

  comments: {
    authorName: [ 'primary', 'base', ],
    bg: 'var(--main-bg-color)',
    border: [ 'neutral', '-4', ],
    divider: [ 'primary', '+1', ],
    date: 'var(--comments-date)',
    highlightedCommentBg: 'var(--comments-highlightedCommentBg)',
    highlightStatus: 'var(--comments-highlightStatus)',
    number: 'var(--comments-number)',
    report: 'var(--comments-report)',
    replyIcon: 'var(--comments-replay-icon)',
    cancelReplayBtn: 'var(--comments-cancelReplyBtn)',
    likeIcon: [ 'primary', 'base', ],
    likeCounter: 'var(--comments-likeCounter)',
    subcommentAuthor: 'var(--comments-subcommentAuthor)',
    subcommentBorder: [ 'primary', '-4', ],
    text: 'var(--main-text-color)',
    formElementsText: [ 'primary', 'base', ],
    formFieldBg: 'var(--comments-field-bg)',
    nameAlertTxt: 'var(--comments-nameAlertTxt)',
  },
  layout: {
    containerBg: 'var(--main-bg-color)',
    rowBg: 'var(--layout-rowBg)',
    sectionTitleA: [ 'primary', 'base', ],
  },

  liveBlog: {
    title: 'var(--live-blog-title)',
    bg: 'var(--main-bg-color)',
    text: 'var(--main-text-color)',
    border: 'var(--live-blog-border)',
    radioBg: 'var(--live-blog-radio-bg)',
    updatesButtonText: [ 'secondary', 'base', ],
    expandBtnFocus: 'var(--live-blog-expand-btn-focus)',
    avatar: 'var(--live-blog-avatar)',
    avatarBg: 'var(--live-blog-avatarBg)',
    liveUpdateBg: 'var(--live-blog-liveUpdateBg)',
    highlightedItemBg: 'var(--live-blog-highlightedItemBg)',
    iconShareFill: 'var(--live-blog-iconShareFillColor)',
  },
  magazine: {
    shareBarZen: 'inherit',
    articleHeader: 'inherit',
    headerText: 'var(--magazine-headerText)',
  },
  authorBio: {
    infoBg: 'var(--authorBio-bg)',
    imageBg: [ 'primary', '-4', ],
  },
  newsletter: {
    plusBg: [ 'quaternary', 'base', ],
    plus: [ 'neutral', '-1', ],
    // Newsletter
    highlightBg: [ 'highlight', 'base', ],
    highlightTextTitle: [ 'neutral', 'base', ],
    highlightText: [ 'neutral', 'base', ],

    // Primary
    primaryBg: [ 'primary', '-2', ],
    primaryTextTitle: [ 'neutral', '-10', ],
    primaryText: [ 'neutral', '-10', ],

    // Single
    singleBg: [ 'neutral', '-10', ],
    singleTextTitle: [ 'neutral', '-1', ],
    singleText: [ 'neutral', '-10', ],
  },
  highlight: {
    base: [ 'quaternary', 'base', ],
    dimm: [ 'quaternary', '-2', ],
  },
  icon: {
    base: [ 'primary', 'base', ],
    text: [ 'neutral', '-3', ],
  },
  a11yMenu: {
    text: [ 'primary', 'base', ],
    textOpenOrHover: [ 'neutral', '-10', ],
    bgOpen: [ 'secondary', 'base', ],
    bgHover: [ 'primary', 'base', ],
  },
  userMenu: {
    bgHover: [ 'primary', 'base', ],
    bgOpen: [ 'secondary', 'base', ],
    iconColor: [ 'primary', 'base', ],
    text: [ 'neutral', '-3', ],
    textOpenOrHover: [ 'neutral', '-10', ],
  },
  headerSearch: {
    text: [ 'neutral', '-3', ],
    bgHover: [ 'primary', 'base', ],
    bgInputOpen: [ 'primary', '-4', ],
    textOpenOrHover: [ 'neutral', '-10', ],
    bgOpen: [ 'secondary', 'base', ],
  },
  masthead: {
    bgButtonIconColor: [ 'neutral', '-3', ],
    border: [ 'primary', 'base', ],
    icon: [ 'primary', 'base', ],
    textSiteLinks: 'white',
    textSiteLinksHover: 'white',
    bgButtonHover: [ 'primary', 'base', ],
    bgMenu: [ 'secondary', 'base', ],
    bgMenuSites: [ 'primary', '+1', ],
    bgMenuHover: [ 'secondary', '+1', ],
    bgMenuHoverSecondary: [ 'secondary', '+2', ],
    menuItemBorder: [ 'primary', '+1', ],
    subMenuBg: [ 'secondary', 'base', ],
    subMenuBgHover: [ 'secondary', '+2', ],
    flippingArrow: [ 'neutral', '-10', ],
    flippingArrowButtonBgOpen: [ 'neutral', '-1', ],
  },
  labelMasthead: {
    bgMenuSites: [ 'neutral', '-6', ],
    textSiteLinks: [ 'commercial', 'base', ],
  },
  mobileNav: {
    barText: [ 'primary', 'base', ],
    barBorder: [ 'primary', 'base', ],
    skewedBg: [ 'primary', 'base', ],
    promotionBg: [ 'quaternary', '-2', ],
    avatarBg: [ 'primary', 'base', ],
    sectionLink: [ 'primary', '+1', ],
    siteLink: [ 'primary', '+1', ],
    moreBackBg: [ 'quaternary', 'base', ],
    moreBackArrow: [ 'primary', 'base', ],
    barIcons: [ 'primary', 'base', ],
    logoBg: [ 'primary', 'base', ],
    logoBgActive: [ 'secondary', 'base', ],
    divider: [ 'primary', 'base', ],
    headerIcon: [ 'quaternary', '-3', ],
    gradientStart: '#f4d835',
    gradientEnd: '#fff169',


    labelText: [ 'commercial', 'base', ],
    labelBg: [ 'neutral', '-7', ],
    labelBorder: [ 'neutral', '-4', ],
  },

  marketingNotification: {
    closeIcon: [ 'primary', 'base', ],
  },

  paragraph: {
    markBg: 'var(--paragraph-markBg)',
  },

  paywall: {
    text: [ 'secondary', 'base', ],
    inverseText: 'white',
    primaryGradientStart: '#169fd1',
    primaryGradientEnd: '#105676',
    secondaryGradientStart: '#f4d835',
    secondaryGradientEnd: '#fff169',
    textMidPage: [ 'primary', '+1', ],
  },
  personalArea: {
    calendarBase: [ 'primary', 'base', ],
    betweenSelectedDates: [ 'primary', '-3', ],
  },
  downloadAplicationPopUp: {
    gradientStart: '#169fd1',
    gradientEnd: '#105676',
  },
  // Primary
  select: {
    primaryBg: 'white',
    primaryBorder: [ 'primary', 'base', ],
    primaryBorderItem: 'var(--select-primaryBorderItem)',
    primaryArrowColor: [ 'primary', 'base', ],
    primaryTextColor: [ 'primary', 'base', ],

    // Primary highlighted
    primaryHighlightedBg: [ 'primary', '-6', ],
    // Primary hover
    primaryHoverBg: [ 'primary', '-6', ],
    // Primary Focus
    primaryFocusBg: [ 'primary', '-6', ],

    primaryContactUsBg: 'var(--select-primaryContactUsBg)',
    primaryContactUsBorder: [ 'primary', 'base', ],
    primaryContactUsBorderItem: 'var(--select-primaryContactUsBorderItem)',
    primaryContactUsArrowColor: [ 'primary', 'base', ],
    primaryContactUsTextColor: [ 'primary', 'base', ],

    // Primary highlighted
    primaryContactUsHighlightedBg: 'var(--select-primaryContactUsHightlightedBg)',
    // Primary hover
    primaryContactUsHoverBg: 'var(--select-primaryContactUsHoverBg)',
    // Primary Focus
    primaryContactUsFocusBg: 'var(--select-primaryContactUsFocusBg)',

    // Neutral
    neutralBg: 'transparent',
    neutralBorder: [ 'bodyText', 'base', ],
    neutralText: [ 'neutral', '-1', ],
    neutralActiveBg: 'transparent !important',
    neutralActiveBorder: [ 'button', 'neutralText', ],
    neutralActiveText: [ 'button', 'neutralText', ],
    neutralFocusText: 'hsl(0,0%,100%)',
    neutralHoverBorder: [ 'button', 'neutralText', ],
    neutralHoverText: [ 'button', 'neutralText', ],
    neutralBorderItem: [ 'bodyText', 'base', ],
    neutralArrowColor: [ 'bodyText', 'base', ],
    neutralTextColor: [ 'button', 'neutralText', ],

    // neutral highlighted
    neutralHighlightedBg: [ 'neutral', '-6', ],
    // neutral hover
    neutralHoverBg: [ 'neutral', '-6', ],
    // neutral Focus
    neutralFocusBg: [ 'neutral', '-6', ],

  },
  tldr: {
    bg: 'var(--tldr-bg)',
    buttonBg: 'var(--tldr-buttonBg)',
    buttonBgHover: '--tldr-buttonBgHover',
    buttonBgActive: 'var(--tldr-buttonBgActive)',
    buttonIcon: 'var(--tldr-buttonIcon)',
  },
  input: {
    // Primary
    primaryBg: 'var(--input-primaryBg)',
    primaryBgWrapper: 'transparent',
    primaryBorder: [ 'primary', '-4', ],
    primaryBorderTextLabel: [ 'primary', '-5', ],
    primaryPlaceholder: [ 'neutral', '-4', ],
    primaryText: [ 'bodyText', 'base', ],
    primaryTextLabel: 'var(--input-primaryTextLabel)',
    primaryTextLabelDisabled: 'var(--input-primaryTextLabelDisabled)',
    primaryTextNote: [ 'neutral', '-2', ],
    primaryAbbr: [ 'tertiary', 'base', ],

    // Primary Focus
    primaryFocusBg: [ 'bg', 'base', ],
    primaryFocusBorder: [ 'primary', 'base', ],

    // Primary Error state
    primaryErrorBorder: [ 'tertiary', '+1', ],
    primaryErrorText: [ 'bodyText', 'base', ],
    primaryErrorTextLabel: [ 'tertiary', 'base', ],
    primaryErrorTextNote: [ 'tertiary', 'base', ],

    // Primary Hover
    primaryHoverBg: [ 'primary', '-5', ],
    primaryHoverBorder: [ 'primary', '-4', ],
    primaryHoverText: [ 'bodyText', 'base', ],

    // primaryContactUs
    primaryContactUsBg: 'var(--input-primaryContactUsBg)',
    primaryContactUsBgWrapper: 'transparent',
    primaryContactUsBorder: 'var(--input-primaryContactUsBorder)',
    primaryContactUsBorderTextLabel: [ 'primary', '-5', ],
    primaryContactUsPlaceholder: [ 'neutral', '-4', ],
    primaryContactUsText: [ 'bodyText', 'base', ],
    primaryContactUsTextLabel: 'var(--input-primaryContactUsTextLabel)',
    primaryContactUsTextLabelDisabled: 'var(--input-primaryContactUsTextLabelDisabled)',
    primaryContactUsTextNote: [ 'neutral', '-2', ],
    primaryContactUsAbbr: [ 'tertiary', 'base', ],

    // Primary Contact Us Focus
    primaryContactUsFocusBg: [ 'bg', 'base', ],
    primaryContactUsFocusBorder: [ 'primary', 'base', ],

    // Primary Contact Us Error state
    primaryContactUsErrorBorder: [ 'tertiary', '+1', ],
    primaryContactUsErrorText: [ 'bodyText', 'base', ],
    primaryContactUsErrorTextLabel: [ 'tertiary', 'base', ],
    primaryContactUsErrorTextNote: [ 'tertiary', 'base', ],

    // Primary Contact Us Hover
    primaryContactUsHoverBg: [ 'primary', '-5', ],
    primaryContactUsHoverBorder: [ 'primary', '-4', ],
    primaryContactUsHoverText: [ 'bodyText', 'base', ],

    // Primary Opaque
    primaryOpaqueBg: 'white',
    primaryOpaqueBgWrapper: 'transparent',
    primaryOpaqueBorder: [ 'primary', '-4', ],
    primaryOpaqueBorderTextLabel: [ 'primary', '-5', ],
    primaryOpaquePlaceholder: [ 'neutral', '-4', ],
    primaryOpaqueText: [ 'bodyText', 'base', ],
    primaryOpaqueTextLabel: [ 'primary', '+1', ],
    primaryOpaqueTextLabelDisabled: [ 'neutral', '-4', ],
    primaryOpaqueTextNote: [ 'neutral', '-2', ],
    primaryOpaqueAbbr: [ 'tertiary', 'base', ],

    // Primary Opaque Focus
    primaryOpaqueFocusBg: 'white',
    primaryOpaqueFocusBorder: [ 'primary', 'base', ],

    // Primary Opaque Error state
    primaryOpaqueErrorBorder: [ 'tertiary', '+1', ],
    primarOpaqueErrorText: [ 'bodyText', 'base', ],
    primaryOpaqueErrorTextLabel: [ 'tertiary', 'base', ],
    primaryOpaqueErrorTextNote: [ 'tertiary', 'base', ],

    // Primary Opaque Hover
    primaryOpaqueHoverBg: [ 'primary', '-5', ],
    primaryOpaqueHoverBorder: [ 'primary', '-4', ],
    primaryOpaqueHoverText: [ 'bodyText', 'base', ],

    // PrimaryInverse
    primaryInverseBg: 'white',
    primaryInverseBgWrapper: 'transparent',
    primaryInverseBorder: [ 'primary', '-4', ],
    primaryInverseBorderTextLabel: [ 'primary', '-5', ],
    primaryInversePlaceholder: [ 'neutral', '-4', ],
    primaryInverseText: [ 'bodyText', 'base', ],
    primaryInverseTextLabel: [ 'primary', '+1', ],
    primaryInverseTextLabelDisabled: [ 'neutral', '-4', ],
    primaryInverseTextNote: 'white',
    primaryInverseAbbr: [ 'primary', 'base', ],

    // PrimaryInverse Focus
    primaryInverseFocusBg: 'white',
    primaryInverseFocusBorder: [ 'primary', 'base', ],

    // PrimaryInverse Error state
    primaryInverseErrorBorder: [ 'tertiary', '+1', ],
    primaryInverseErrorText: [ 'bodyText', 'base', ],
    primaryInverseErrorTextLabel: [ 'tertiary', 'base', ],
    primaryInverseErrorTextNote: [ 'tertiary', 'base', ],

    // PrimaryInverse Hover
    primaryInverseHoverBg: [ 'primary', '-5', ],
    primaryInverseHoverBorder: [ 'primary', '-4', ],
    primaryInverseHoverText: [ 'bodyText', 'base', ],

    // PrimaryMediumInverse
    primaryMediumInverseBg: 'white',
    primaryMediumInverseBgWrapper: 'transparent',
    primaryMediumInverseBorder: [ 'primary', '-4', ],
    primaryMediumInverseBorderTextLabel: [ 'primary', '-5', ],
    primaryMediumInversePlaceholder: [ 'neutral', '-4', ],
    primaryMediumInverseText: [ 'bodyText', 'base', ],
    primaryMediumInverseTextLabel: [ 'primary', '+1', ],
    primaryMediumInverseTextLabelDisabled: [ 'neutral', '-4', ],
    primaryMediumInverseTextNote: [ 'secondary', 'base', ],
    primaryMediumInverseAbbr: [ 'primary', 'base', ],

    // PrimaryMediumInverse Focus
    primaryMediumInverseFocusBg: 'white',
    primaryMediumInverseFocusBorder: [ 'primary', 'base', ],

    // PrimaryInverse Error state
    primaryMediumInverseErrorBorder: [ 'tertiary', '+1', ],
    primaryMediumInverseErrorText: [ 'bodyText', 'base', ],
    primaryMediumInverseErrorTextLabel: [ 'tertiary', 'base', ],
    primaryMediumInverseErrorTextNote: [ 'tertiary', 'base', ],

    // PrimaryInverse Hover
    primaryMediumInverseHoverBg: [ 'primary', '-5', ],
    primaryMediumInverseHoverBorder: [ 'primary', '-4', ],
    primaryMediumInverseHoverText: [ 'bodyText', 'base', ],

    // Search
    searchBg: 'transparent',
    searchBgWrapper: 'transparent',
    searchBorder: 'transparent',
    searchBorderTextLabel: [ 'primary', '-5', ],
    searchPlaceholder: [ 'neutral', '-4', ],
    searchText: [ 'bodyText', 'base', ],
    searchTextLabel: [ 'primary', '+1', ],
    searchTextLabelDisabled: [ 'neutral', '-4', ],
    searchTextNote: [ 'neutral', '-3', ],
    searchAbbr: [ 'tertiary', 'base', ],

    // Search Focus
    searchFocusBg: 'transparent',
    searchFocusBorder: 'transparent',

    // Search Error state
    searchErrorBorder: [ 'tertiary', '+1', ],
    searchErrorText: [ 'bodyText', 'base', ],
    searchErrorTextLabel: [ 'tertiary', 'base', ],
    searchErrorTextNote: [ 'tertiary', 'base', ],

    // Search Hover
    searchHoverBg: 'transparent',
    searchHoverBorder: 'transparent',
    searchHoverText: [ 'bodyText', 'base', ],
  },
  image: {
    bgc: [ 'neutral', '-6', ],
  },
  link: {
    base: 'var(--link-base)',
    textLink: 'var(--link-base)',
    mainBlockBase: [ 'primary', 'base', ],
    active: [ 'secondary', 'base', ],
    borderBottom: 'var(--link-borderBottom)',
    borderBottomHover: 'transparent',
    a11yOnDark: [ 'primary', '-2', ],
    a11yOnLight: [ 'primary', '+1', ],
    darkMainBlockRelatedArticles: '#86d8f3',
  },
  list: {
    listViewHeader: [ 'primary', 'base', ],
    listViewHeaderIconBack: 'inherit',
    listViewHeaderIconBackBg: [ 'quaternary', 'base', ],
    listViewHeaderCommercial: [ 'commercial', 'base', ],
  },
  radioButton: {
    // Primary
    primaryBg: 'var(--radioButton-primaryBg)',
    primaryBgChecked: [ 'primary', 'base', ],
    primaryBorder: [ 'primary', 'base', ],
    primaryBorderDisabled: [ 'neutral', '-5', ],
    primaryCheck: 'white',
    primaryRipple: [ 'primary', 'base', ],

    // Secondary
    secondaryBg: 'white',
    secondaryBgChecked: [ 'secondary', 'base', ],
    secondaryBorder: [ 'secondary', 'base', ],
    secondaryBorderDisabled: [ 'neutral', '-5', ],
    secondaryCheck: 'white',
    secondaryRipple: [ 'secondary', 'base', ],

    // Tertiary
    tertiaryBg: [ 'neutral', '-5', ],
    tertiaryBgChecked: [ 'tertiary', 'base', ],
    tertiaryBorder: [ 'neutral', '-5', ],
    tertiaryBorderDisabled: [ 'neutral', '-5', ],
    tertiaryCheck: 'white',
    tertiaryRipple: [ 'tertiary', 'base', ],
  },

  sales: {
    '-2': '#FFF7E5',
    '-1': '#FFBD45',
    base: '#FFA500',
    '+1': '#FA9600',
    '+2': '#ED8600',
    a11yOnLight: '#A7610C',
  },

  shareBar: {
    button: [ 'primary', 'base', ],
    buttonHover: [ 'primary', '-1', ],
    borderTop: [ 'neutral', '-5', ],
  },

  // state
  disabled: {
    text: [ 'neutral', '-4', ],
    bg: [ 'neutral', '-5', ],
  },

  negative: {
    '-2': '#ff8585',
    '-1': [ 'tertiary', '-1', ],
    base: [ 'tertiary', 'base', ],
    '+1': [ 'tertiary', '+1', ],
    a11yOnDark: [ 'tertiary', '-1', ],
  },
  positive: {
    '-3': '#E7FFE5',
    '-2': '#6BCC66',
    '-1': '#5BB856',
    base: '#2F872A',
    '+1': '#266D22',
    '+2': '#194716',
    a11yOnDark: [ 'positive', '-1', ],
  },
  specialPromotions: {
    // Primary
    primaryBg: [ 'quaternary', 'base', ],
    // PrimaryInverse
    primaryInverseBg: [ 'tertiary', '-4', ],
  },
  quote: {
    text: [ 'primary', 'base', ],
    visualElement: [ 'primary', 'base', ],
    creditHighlight: 'var(--quote-creditHighlight)',
    creditText: 'var(--quote-creditText)',
  },
  quoteTeaser: {
    bg: [ 'neutral', '-2', ],
    bgMobile: [ 'neutral', '-2', ],
    svg: [ 'quaternary', 'base', ],
    svgMobile: [ 'quaternary', 'base', ],
    text: 'white',
    textMobile: 'white',
    footer: [ 'quaternary', 'base', ],
    footerMobile: [ 'quaternary', 'base', ],
  },
  teaserFooter: {
    comments: [ 'primary', 'base', ],
  },
  teaserAuthors: {
    author: [ 'primary', 'base', ],
  },
  vogelList: {
    title: [ 'primary', 'base', ],
    titleBg: [ 'primary', '-6', ],
  },
  topNews: {
    item: [ 'neutral', '-1', ],
    lastItem: [ 'primary', '+1', ],
    hoverUnderline: [ 'primary', '-2', ],
  },
  calculonList: {
    t5FooterColor: [ 'quaternary', 'base', ],
    t5Kicker: [ 'quaternary', 'base', ],
  },
  zoidbergList: {
    title: [ 'primary', 'base', ],
  },
  garciaList: {
    authorColor: [ 'primary', 'base', ],
  },
  mousePad: {
    index: [ 'primary', 'base', ],
  },
  ogiList: {
    headlineColor: [ 'quaternary', 'base', ],
    headlineBgColor: [ 'neutral', '-1', ],
    readingtimeHighlight: [ 'quaternary', 'base', ],
    readingtimeColor: [ 'neutral', '-1', ],
    readingTimeTextColor: [ 'neutral', '-1', ],
  },
  tabElement: {
    tabText: [ 'primary', '+1', ],
    activeTabBg: [ 'quaternary', 'base', ],
    activeTabColor: 'inherit',
    focusBorderBottom: 'currentColor',
    activeBorderTop: 'transparent',
    tabsBg: [ 'neutral', '-10', ],
    tabListBg: [ 'neutral', '-10', ],
    tabListText: [ 'neutral', '-3', ],
    tabListBorderBottom: [ 'neutral', '-6', ],
  },

  osaka: {
    text: [ 'neutral', '-1', ],
    footerText: [ 'neutral', '-3', ],
    nextBtnBg: [ 'quaternary', 'base', ],
    border: [ 'primary', 'base', ],
    borderEnd: [ 'neutral', '-4', ],
    promotedBg: [ 'neutral', '-6', ],
  },

  schoonhoven: {
    kicker: [ 'primary', 'base', ],
    rssLink: [ 'primary', 'base', ],
    rssLinkMobile: [ 'primary', 'base', ],
  },

  audioPlayerArticle: {
    bg: 'white',
    bgWebView: 'var(--audioPlayerArticle-bgWebView)',
    color: [ 'secondary', 'base', ],
    playBg: [ 'primary', '-6', ],
    playBgWebView: 'var(--audioPlayerArticle-playBgWebView)',
    playBgPodcastWebView: [ 'quaternary', 'base', ],
    rail: [ 'primary', '-6', ],
    progressBar: [ 'quaternary', 'base', ],
    mozFocusRing: [ 'quaternary', 'base', ],
    playBtn: 'var(--audioPlayerArticle-playBg)',
    playBtnPodcastWebView: [ 'neutral', '-1', ],
    borderColor: [ 'neutral', '-5', ],
    exclusiveColor: [ 'primary', 'base', ],
    titleColor: [ 'neutral', 'base', ],
  },

  audioPlayerArticleBody: {
    header: [ 'secondary', '+1', ],
  },

  audioPlayer: {
    bg: [ 'primary', 'base', ],
    color: 'white',
    playBg: 'white',
    rail: 'white',
    progressBar: [ 'primary', 'base', ],
    mozFocusRing: [ 'primary', 'base', ],
    playBtn: [ 'primary', 'base', ],

    listItemBg: 'transparent',
    listItemColor: [ 'secondary', 'base', ],
    listItemPlayBg: [ 'primary', '-4', ],
    listItemPlayBtn: [ 'secondary', 'base', ],
    listItemRail: [ 'primary', '-4', ],
  },

  audioPlayerLabel: {
    bg: [ 'primary', 'base', ],
    color: 'white',
    playBg: [ 'neutral', '-2', ],
    rail: [ 'neutral', '-7', ],
    progressBar: [ 'neutral', '-7', ],
    mozFocusRing: [ 'neutral', '-7', ],
    playBtn: [ 'primary', 'base', ],

    listItemBg: 'transparent',
    listItemColor: [ 'neutral', '-2', ],
    listItemPlayBg: [ 'neutral', '-7', ],
    listItemPlayBtn: [ 'neutral', '-2', ],
    listItemRail: [ 'neutral', '-2', ],
  },

  toolbar: {
    buttonHoverBg: [ 'neutral', '+1', ],
  },
  gallery: {
    highlight: [ 'quaternary', 'base', ],
  },

  noteLink: {
    color: [ 'primary', 'base', ],
  },


  podcastArticle: {
    channelLink: [ 'primary', 'base', ],
    channelLinkHover: [ 'neutral', '-1', ],
    headerTextColor: [ 'neutral', '-1', ],
    headerTextColorWebView: 'white',
    headlineBorderColor: [ 'layout', 'rowBg', ],
    headerAudioText: 'white',
    headerAudioPlay: [ 'neutral', '-1', ],
    headerAudioPlayBg: [ 'quaternary', 'base', ],
    headerAudioProgress: [ 'podcastArticle', 'headerAudioPlayBg', ],
    headerAudioProgressRail: [ 'primary', '-4', ],
  },

  recipeArticle: {
    headerTextColor: 'var(--recipeArticle-headerTextColor)',
    headlineTextColor: 'var(--recipeArticle-headerTextColor)',
    headlineBorderColor: [ 'primary', ],
    iconColor: [ 'primary', ],
  },
  // social
  facebook: {
    '-2': '#F2F6FF',
    '-1': '#4766A6',
    base: '#3b5998',
    '+1': '#2A4682',
    messenger: '#0084FF',
  },
  twitter: {
    '-2': '#F2FAFF',
    '-1': '#67BEF4',
    base: '#1DA1F2',
    '+1': '#1888CC',
    '+2': '#1577B2',
  },
  whatsapp: {
    '-2': '#F0FFF5',
    '-1': '#58F593',
    base: '#25D366',
    '+1': '#12B850',
    '+2': '#08993E',
  },
  gplus: '#DB4437',
  linkedin: '#0077B5',
  pinterest: '#BD081C',
  snapchat: '#FFFC00',

  searchEngine: {
    searchBg: [ 'secondary', 'base', ],
    searchFg: 'white',
    searchLogo: 'white',
    autoCompleteBorder: 'currentColor',
    resultsBg: [ 'bg', 'base', ],
    instantSearchWrapperBg: [ 'bg', 'base', ],
    resultsFg: [ 'primary', ],
    resultsLogo: [ 'neutral', '+2', ],
    suggestionsBg: [ 'primary', '+1', ],
    suggestionsHighlightedBg: [ 'primary', 'base', ],
    secondarySuggestionsBg: [ 'secondary', 'base', ],
    hotTopicsBg: 'rgba(255, 255, 255, 0.85)',
    hotTopicsText: [ 'secondary', 'base', ],
    hotTopicsHeadline: 'white',
  },

  mainGradient: {
    base: 'var(--main-gradient)',
  },

  gradientA: {
    from: [ 'primary', '-2', ],
    to: [ 'primary', ],
    fgColor: [ 'neutral', ],
  },

  gradientB: {
    from: [ 'quaternary', 'base', ],
    to: [ 'quaternary', '-3', ],
    fgColor: [ 'neutral', ],
    logoColor: [ 'primary', ],
  },

  topStrip: {
    text: 'white',
    a11yText: [ 'neutral', '+2', ],
  },

  tags: {
    header: [ 'neutral', 'base', ],
    tag: 'var(--tags-tag)',
    icon: [ 'primary', 'base', ],
    iconSuccess: [ 'positive', 'base', ],
  },

  blackTheme: {
    backgroundColor: [ 'neutral', 'base', ],
    textColor: [ 'neutral', '-10', ],
    shareBarButton: [ 'neutral', '-10', ],
    shareBarButtonHover: [ 'quaternary', 'base', ],
    captionText: 'var(--caption-color)',
    mastheadBg: [ 'neutral', 'base', ],
    mastheadText: [ 'neutral', '-10', ],
    mastheadLogo: [ 'neutral', '-10', ],
    mastheadIcon: [ 'neutral', '-10', ],
    mastheadBorder: 'transparent',
  },

  yellowTheme: {
    backgroundColor: [ 'quaternary', 'base', ],
    textColor: [ 'neutral', '-1', ],
    shareBarButton: [ 'neutral', '-1', ],
    shareBarButtonHover: [ 'neutral', '-2', ],
    captionText: 'var(--caption-color)',
    mastheadBg: [ 'neutral', 'base', ],
    mastheadText: [ 'neutral', '-10', ],
    mastheadLogo: [ 'neutral', '-10', ],
    mastheadIcon: [ 'neutral', '-10', ],
    mastheadBorder: 'transparent',
  },
  topicInfoHeader: {
    borderColor: [ 'primary', 'base', ],
    textColor: [ 'primary', 'base', ],
    logoColor: [ 'primary', '-4', ],
    logoFill: [ 'neutral', '-10', ],
    avatarBg: '#f6f6f6',
  },
  logoLight: {
    tm: 'var(--logoLight-tm)',
    hdc: 'var(--logoLight-hdc)',
    htz: [ 'primary', '-4', ],
  },

  defaultTheme: {
    backgroundColor: [ 'neutral', '-10', ],
    textColor: [ 'neutral', '-1', ],
    shareBarButton: [ 'primary', 'base', ],
    shareBarButtonHover: [ 'primary', '-1', ],
    captionText: [ 'neutral', '-1', ],
    mastheadBg: [ 'neutral', '-10', ],
    mastheadText: [ 'neutral', '-3', ],
    mastheadLogo: [ 'neutral', '-1', ],
    mastheadIcon: [ 'primary', 'base', ],
    mastheadBorder: [ 'primary', 'base', ],
  },

  commentsPaywall: {
    bgc: '#efe2d3',
  },

  labels: {
    relatedArticleTitle: '#c02c8e',
  },

  Infographics: {
    main: 'var(--infographic-main)',
    bg: 'var(--infographic-bg)',
    innerBg: 'var(--infographic-innerBg)',
    regularColumn: 'var(--infographic-main)',
    boldColumn: 'var(--infographic-boldColumn)',
    timelineBorder: 'var(--infographic-timelineBorder)',
    timelineBullet: 'var(--infographic-timelineBullet)',
    timelineTitle: 'var(--infographic-timelineBullet)',
    bulletContent: 'var(--infographic-main)',
    text: 'var(--infographic-text)',
    chatBg: 'var(--infographic-chatBg)',
    qaBorder: 'var(--infographic-qaBorder)',
  },

  Weekend: {
    bg: [ 'quaternary', 'base', ],
    innerBg: [ 'neutral', '-10', ],
    exclusive: [ 'primary', '+1', ],
  },

  readingList: {
    primary: [ 'primary', 'base', ],
    highlight: [ 'quaternary', 'base', ],
  },

  seriesArticles: {
    text: 'var(--seriesArticles-text)',
    border: 'var(--seriesArticles-borderColor)',
    loadMore: 'var(--seriesArticles-loadMore)',
    focusBg: 'var(--seriesArticles-focusBg)',
  },

  reviewAmenities: {
    bg: 'var(--reviewAmenities-bg)',
    innerBg: 'var(--reviewAmenities-innerBg)',
  },

  gameArticle: {
    bg: 'var(--gameArticle-bg)',
    innerBg: 'var(--gameArticle-innerBg)',
    statisticsText: 'var(--gameArticle-statistics-text)',
    tabNotActive: 'var(--gameArticle-tabNotActive)',
    border: 'var(--gameArticle-border)',
    headerTabBg: 'var(--gameArticle-headerTabBg)',
    headerTabText: 'var(--gameArticle-headerTabText)',
  },

  twentyQuestions: {
    questionCardBg: 'var(--twentyQuestions-questionCardBg)',
    circlesBg: 'var(--twentyQuestions-circlesBg)',
    welcomeTxt: 'var(--twentyQuestions-welcomeTxt)',
    welcomeCircleStroke: 'var(--twentyQuestions-welcomeCircleStroke)',
    counterColor: 'var(--twentyQuestions-counterColor)',
    answerBg: 'var(--twentyQuestions-answerBg)',
    positiveBg: 'var(--twentyQuestions-positiveBg)',
    negativeBg: 'var(--twentyQuestions-negativeBg)',
  },

  placeholder: {
    bg: 'var(--placeholder-bg)',
  },

  marco: {
    bg: [ 'neutral', '-10', ],
  },

  faceAffairs: {
    text: 'var(--faceAffairs-text-color)',
  },

  wordle: {
    base: 'var(--wordle-key-base)',
    grey: 'var(--wordle-key-grey)',
    yellow: 'var(--wordle-key-yellow)',
    green: 'var(--wordle-key-green)',
    boardTxtColor: 'white',
    keyboardMarkedTxtColor: 'white',
    moreGameBg: 'var(--wordle-more-game-bg)',
    statsBg: 'var(--wordle-stats-bg)',
    socialIcons: 'var(--wordle-social-icons)',
    navigationText: 'var(--wordle-navigation-text)',
    paywallBorder: 'var(--wordle-paywall-border)',
    paywallBg: 'var(--wordle-paywall-bg)',
    arrowBg: 'var(--wordle-arrowBg)',
    arrowBgActive: 'var(--wordle-arrowBgActive)',
    arrowColor: 'var(--wordle-arrowColor)',
    arrowColorActive: 'var(--wordle-arrowColorActive)',
  },

  scienceRiddle: {
    dialogBg: 'var(--scienceRiddle-dialog-bg)',
    instructionBgColor: 'var(--scienceRiddle-instruction-bg-color)',
    scienceRiddleAuthorName: 'var(--scienceRiddle-author-name)',
  },

  conferenceArticle: {
    bgColor: [ 'neutral', '-6', ],
    color: [ 'neutral', '-1', ],
    HColor: '#271feb',
    bgShareIcons: '#271feb',
    colorShareIcons: [ 'neutral', '-6', ],
    teaserColor: [ 'neutral', '-1', ],
    borderColor: '#271feb',
    pColor: [ 'neutral', '-1', ],
  },

  giftTooltip: {
    bg: 'var(--color-primary--4)',
    border: 'var(--color-primary-base)',
    icon: 'var(--color-primary-base)',
  },
};


export default colors;
